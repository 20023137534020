import { render, staticRenderFns } from "./Overpayment.vue?vue&type=template&id=ba90b3d8"
import script from "./Overpayment.vue?vue&type=script&lang=js"
export * from "./Overpayment.vue?vue&type=script&lang=js"
import style0 from "./Overpayment.vue?vue&type=style&index=0&id=ba90b3d8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports