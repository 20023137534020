<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="action" slot-scope="{ columnData }">
				<a @click="Detail(columnData.loanId)"> Details </a>
				<a-divider type="vertical" />
				<a @click="ShowModal(columnData)"> Refund </a>
			</template>
		</search-list-table>
		<a-modal class="overpayment" v-drag-modal v-model="createModalShow" title="Refund" :destroyOnClose="true" width="600px" @ok="Refund">
			<p>Please input the amount that has already been refunded to the client</p>
			<p><a-input-number id="inputNumber" v-model="refundAmount" :min="0" :max="columnData.amount" /></p>
			<p><a-statistic title="Remaining Overpayment:" :value="columnData.amount - refundAmount" :precision="2" /></p>
		</a-modal>
	</div>
</template>

<script>
import { apiOverpaidPage, apiOverpaidRefund } from '@/api/loan'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			branchOfficeList: [],
			createModalShow: false,
			refundAmount: 0,
			columnData: {}
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['clientName']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['nationalId']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				},
				{
					label: 'Last Overpayment Date',
					type: 'range-picker',
					validateField: ['lastRepaymentDate', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 100,
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'caseId',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'clientName',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'nationalId',
					align: 'center'
				},
				{
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					align: 'center',
					dataIndex: 'registrationNumber'
				},
				{
					title: this.$t('table.applyInfo.cloums.overpayment'),
					dataIndex: 'amount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: 'Last Overpayment Date',
					dataIndex: 'lastRepaymentDate',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 250,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiOverpaidPage({ ...initParams })
		},
		Detail(id) {
			this.$router.push({
				name: 'menus.customerManagementMenu.lendingdetail',
				query: {
					id
				}
			})
		},
		ShowModal(columnData) {
			this.columnData = columnData
			this.refundAmount = 0
			this.createModalShow = true
		},
		Refund() {
			if (!(this.refundAmount > 0)) {
				this.$message.error('Amount error!')
				return false
			}
			this.$confirm({
				title: '',
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					apiOverpaidRefund({
						loanId: this.columnData.loanId, //案件id
						amount: this.refundAmount //退还金额
					})
						.then(() => {
							this.createModalShow = false
							this.$refs.searchListTable.initTable(1)
						})
						.catch((err) => {})
				},
				onCancel() {}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>

<style lang="less">
.overpayment {
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	p {
		padding: 10px 0;
	}
	.ant-input-number {
		height: 40px;
		line-height: 40px;
		width: 300px;
	}
	.ant-statistic-title,
	.ant-statistic-content {
		display: inline-block;
	}
	.ant-statistic-title {
		color: rgba(0, 0, 0, 0.65);
		font-size: 14px;
		margin-right: 10px;
	}
	.ant-statistic-content {
		font-weight: 600;
	}
}
</style>
